.subheader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.subheader .logout_button {
  max-width: 7rem;
  margin-bottom: 1rem;
}
.message_no_user {
  text-align: center;
  display: flex;
  justify-content: center;
  animation: ce;
  font-size: 1.25rem;
  width: 100%;
}
